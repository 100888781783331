import React from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { Button, FloatButton } from 'antd';
import { useRouter } from 'next/router';
import { ArrowRightOutlined } from '@ant-design/icons';
import classNames from 'classnames';

const MeetMyBabyContent = ({ stylingVisible }: { stylingVisible?: boolean }) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  const onDashboardClick = () => {
    router.push(`/influencers`);
  };

  return (
    <div>
      {!stylingVisible && (
        <FloatButton
          className={classNames(styles.floatBtnContent, 'custom-float-btn')}
          shape="square"
          style={{ right: 32 }}
          description={t(`Try-On Now`)}
          onClick={onDashboardClick}
        />
      )}

      <div className={styles.content}>
        <p className={styles.title}>
          {t('Get the trendiest hairstyles, Try it before you cut it!')}
        </p>
        <p className={styles.desc}>
          {t('67,342 AI hairstyles already created for 1,539 happy customers!')}
        </p>
        <div className={styles.btnContainer} id={'btnContainer'}>
          <Button type="primary" className={styles.signUpBtn} onClick={onDashboardClick}>
            {t('Start Your Hair Trend')}
            <ArrowRightOutlined />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MeetMyBabyContent;
